import { useFormik } from "formik";
import { Grid, Button, Input, Loader } from "../../components";
import { useNavigate } from "react-router-dom";
import { SignInSchema } from '@vcm-painel/validations'
import { useAPI } from "../../API";

export function Login () { 
    const navigate = useNavigate()
    
    const [login, loginAction] = useAPI({
        url: '/auth/signin',
        method: 'POST'
    }, {
        manual: true
    })

    const formik = useFormik({
        initialValues: {
            password: '',
        },
        onSubmit: async (values) => {
            const {data} = await loginAction({
                data: values
            })

            if (data?.token) {
                window.localStorage.setItem('@vcm-painel/token', data?.token)
                navigate('/admin')
            }
        },
        validationSchema: SignInSchema,
        validateOnMount: true
    })
    
    return (
        <Grid container justifyContent="center" minHeight={"100vh"} padding={4} alignItems="center" style={{
            backgroundColor: 'black',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Grid item xs={12} sm={4}>
                {login.loading ? (
                    <Loader />
                ) : (
                <form onSubmit={formik.handleSubmit}>
                    <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                        <img src="/logo.png" alt="Logo" style={{width: '50%'}}/>
                        <Grid item xs={12}> 
                            <Input 
                                label="Senha"
                                name="password"
                                type="password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password}
                                helperText={formik.touched.password && formik.errors.password}
                                error={!!formik.touched.password && !!formik.errors.password}
                            />
                        </Grid>
                        <Grid item marginTop={2} xs={12}>
                            <Button disabled={!formik.isValid} type="submit">Entrar</Button>
                        </Grid>
                    </Grid>
                </form>
                )}
            </Grid>
        </Grid>
    )
}