import { Alert, Grid, Typography } from "../../components";
import { useTheme } from "@mui/material";

export function Thankyou () { 
    const theme = useTheme()

    return (
        <Grid container justifyContent="center" minHeight={"100vh"} padding={4}  style={{
            backgroundColor: 'black',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                    <img src="/logo.png" alt="Logo" style={{width: '50%'}} onClick={() => window.location.href = '/' } />
                    <Grid item xs={12}>
                        <Typography variant="h4" color={theme.palette.primary.main}>Obrigado!</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{marginTop: 3, marginBottom: 3}}>
                        <Typography variant="body1" color={theme.palette.text.primary}>
                            Seu endereço foi cadastrado com sucesso! Basta aguardar o prazo descrito.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{
                        marginTop: 2
                    }}>
                    <Alert color="warning" icon={false}>
                        <p style={{textAlign: 'center', fontWeight: 'bold', fontSize: 'large'}}>ATENÇÃO - PRAZO DE ENVIO DO BRINDE!</p>
                        <ul style={{textAlign: 'left'}}>
                            <li>Todos os endereços informados entre o dia <strong>primeiro</strong> e <strong>último</strong> do mês é feito o despacho até o dia 15 do mês seguinte e então começa a contar o prazo de 60 dias dos correios.</li>
                            <li>Envios internacionais poderão ter prazo de mais 30 dias, adicionalmente.</li>
                        </ul>
                    </Alert>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}