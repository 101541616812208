import { useFormik } from "formik";
import { Grid, Alert, AlertTitle, Typography, Paper, Divider, CurrencyInput, NumericInput } from "../../components";
import currency from 'currency.js'
import * as Yup from 'yup'
import { useTheme } from "@mui/material";

const REQUIRED_FIELD = 'Este campo é obrigatório'
const MUST_BE_GREATER_THAN_ZERO = 'Deve ser maior ou igual a zero'

export function Calculator () { 
    const theme = useTheme()

    const formik = useFormik({
        initialValues: {
            cost: '',
            miles: '',
            cpm: '',
            fee: ''
        },
        validationSchema: Yup.object({
            cost: Yup.string().test({
                test: value => {
                    if (Number(value) <= 0) {
                        return false
                    }
        
                    return true
                },
                message: MUST_BE_GREATER_THAN_ZERO
            }).required(REQUIRED_FIELD),
            miles: Yup.string().test({
                test: value => {
                    if (Number(value) <= 0) {
                        return false
                    }
        
                    return true
                },
                message: MUST_BE_GREATER_THAN_ZERO
            }).required(REQUIRED_FIELD),
            cpm: Yup.string().test({
                test: value => {
                    if (Number(value) <= 0) {
                        return false
                    }
        
                    return true
                },
                message: MUST_BE_GREATER_THAN_ZERO
            }).required(REQUIRED_FIELD),
            fee: Yup.string().test({
                test: value => {
                    if (Number(value) <= 0) {
                        return false
                    }
        
                    return true
                },
                message: MUST_BE_GREATER_THAN_ZERO
            }).required(REQUIRED_FIELD),
        }),
        validateOnMount: true,
        isInitialValid: false,
        onSubmit: () => {},
    })

    const priceWithMiles = currency(currency(Number(formik.values.miles), { precision: 3 }).divide(1000).value, { precision: 3 }).multiply(formik.values.cpm).add(formik.values.fee).value

    const isMilesBetter = Number(formik.values.cost) > Number(priceWithMiles)

    const diffOrPrice = isMilesBetter ? currency(Number(formik.values.cost)).subtract(priceWithMiles).value : currency(priceWithMiles).subtract(Number(formik.values.cost)).value
    const savingPct = isMilesBetter ? currency(diffOrPrice, {precision: 2}).divide((Number(formik.values.cost)),).multiply(100).value : currency(diffOrPrice, {precision: 2}).divide(priceWithMiles).multiply(100).value


    const betterStyle = {
        borderColor: theme.palette.success.main,
        borderWidth: 2
    }

    const badStyle = {
        borderColor: theme.palette.error.main,
        borderWidth: 2
    }

    const cashContainerStyles = !formik.isValid ? {} : isMilesBetter ? badStyle : betterStyle
    const milesContainerStyles = !formik.isValid ? {} : !isMilesBetter ? badStyle : betterStyle

    return (
        <Grid container justifyContent="center" minHeight={"100vh"} padding={4} spacing={2} style={{
            backgroundColor: 'black',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}>
            <Grid item xs={12} textAlign="center">
                <img src="/logo.png" alt="Logo" style={{height: '20vh'}}/>
            </Grid>
            {formik.isValid && (<Grid item xs={10} md={6} lg={4}>
                <Alert>
                    <AlertTitle>
                        {isMilesBetter ? 'Milhas!' : 'Dinheiro!'}
                    </AlertTitle>
                        <p>
                        Valor em Dinheiro: <strong>{currency(Number(formik.values.cost)).format({
                            symbol: 'R$ ',
                            decimal: ',',
                            separator: '.'
                        })}</strong>
                        </p>
                        <p>
                            Valor com Milhas: <strong>{currency(priceWithMiles).format({
                            symbol: 'R$ ',
                            decimal: ',',
                            separator: '.'
                        })}</strong>
                        </p>
                        <p>
                            Economia: <strong>{currency(diffOrPrice).format({
                            symbol: 'R$ ',
                            decimal: ',',
                            separator: '.'
                        })} ({savingPct} %)
                        </strong>
                        </p>
                </Alert>
            </Grid>)}
            <Grid item xs={10}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6} item>
                        <Paper variant={"outlined"} style={cashContainerStyles}>
                            <div style={{
                                padding: 15,
                                textAlign: 'center'
                            }}>
                                <Typography variant="h6">Valor em Dinheiro</Typography>
                            </div>
                            <Divider />
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container padding={2} justifyContent="center" textAlign={"center"}>
                                    <Grid item xs={12}> 
                                        <CurrencyInput
                                            label="Valor (R$)"
                                            name="cost"
                                            onChangeValue={(_, originalValue) => {
                                                formik.setFieldValue('cost', originalValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cost}
                                            helperText={(formik.touched.cost && formik.errors.cost)}
                                            error={!!formik.touched.cost && !!formik.errors.cost}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                    <Grid xs={12} md={6} item>
                    <Paper variant={"outlined"} color="green"
                        style={milesContainerStyles}
                    >
                            <div style={{
                                padding: 15,
                                textAlign: 'center'
                            }}>
                                <Typography variant="h6">Valor em Milhas</Typography>
                            </div>
                            <Divider />
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container padding={2} spacing={2} justifyContent="center" textAlign={"center"}>
                                    <Grid item xs={12} md={4}> 
                                        <NumericInput
                                            label="Quantidade de Milhas"
                                            name="miles"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.miles}
                                            helperText={formik.touched.miles && formik.errors.miles}
                                            error={!!formik.touched.miles && !!formik.errors.miles}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}> 
                                        <CurrencyInput
                                            label="Custo por Milheiro (R$)"
                                            name="cpm"
                                            onChangeValue={(_, originalValue) => {
                                                formik.setFieldValue('cpm', originalValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.cpm}
                                            helperText={(formik.touched.cpm && formik.errors.cpm)}
                                            error={!!formik.touched.cpm && !!formik.errors.cpm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}> 
                                        <CurrencyInput
                                            label="Taxas (R$)"
                                            name="fee"
                                            onChangeValue={(_, originalValue) => {
                                                formik.setFieldValue('fee', originalValue)
                                            }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fee}
                                            helperText={(formik.touched.fee && formik.errors.fee)}
                                            error={!!formik.touched.fee && !!formik.errors.fee}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}